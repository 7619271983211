<template>
    <div class="MemberInfo" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属机构">
                            <el-select v-model="form.belongDept" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="会员名称/手机号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="性别">
                            <el-select placeholder="性别" v-model="form.sex">
                                <el-option label="请选择" value="" />
                                <el-option label="男" value="0" />
                                <el-option label="女" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.member.trade.open')"
            >
                查询会员
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="400"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员名称" min-width="120" prop="name" v-if="showColumn('name')" />
                <el-table-column label="手机号" width="100" prop="mobile" v-if="showColumn('mobile')" />
                <el-table-column label="性别" width="80" prop="sex" key="sex" v-if="showColumn('sex')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex | sex }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="注册时间" min-width="95" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column label="所属机构" width="140" prop="belongDept" v-if="showColumn('belongDept')" />
                <el-table-column
                    label="注册渠道"
                    width="150"
                    prop="regChannel"
                    key="regChannel"
                    v-if="showColumn('regChannel')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.regChannel | regChannel }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="operate"
                    key="operate"
                    label="操作"
                    width="120"
                    header-align="center"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.member.open')"
                            >查看60天交易
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'MemberInfo',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                belongDept: '',
                sex: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },

            meta: {
                groups: [],
                deptCodes: [],
            },
            tableData: [],
            loadingDeptGroupFlag: true,
            url: {
                page: '/member/page',
                delete: '/member/delete',
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
            },
        };
    },
    filters: {
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            }
            if (sex == '1') {
                return '女';
            }
        },
        regChannel(regChannel) {
            if (typeof regChannel == 'undefined') {
                return '';
            }
            if (regChannel == '8') {
                return '后台新建';
            }
            if (regChannel == '10') {
                return '会员初始化';
            }
        },
    },
    mounted() {
        this.$efApi.deptApi.managedAllDepts().then((rst) => {
            this.meta.deptCodes = rst || [];
            this.loadingDeptGroupFlag = false;
        });
        this.handleQuery();
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            _this.form.regDept = '';
            _this.meta.deptCodes = [];
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.form.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            const _params = row;
            _params.nowDeptCode = this.form.regDept;
            _params.nowDeptGroupCode = this.form.deptGroupCode;
            Util.nameJump(this, 'menu.report.member.trade.detail', ['报表管理', '会员60天交易', '详情'], {
                form: _params,
            });
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
};
</script>

<style scoped>
.MemberInfo .el-form-item {
    margin-bottom: 0;
}
</style>
