<template>
    <div class="CheckTableShowColumnDialog">
        <ef-dialog
            title="选择表格要展示的列"
            :visible.sync="dialogFormVisible"
            width="50%"
            top="60px"
            @confirm="confirmSelection"
        >
            <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAllFlag"
                @change="checkAllChange"
                border
                style="margin-bottom: 20px"
                >勾选全部列</el-checkbox
            >
            <el-checkbox-group v-model="checkedColumnPropertyArr" @change="handleCheckedChange">
                <template v-for="(column, index) in tableColumns">
                    <el-checkbox :label="column.property" :key="`${column.property}---${index}`">
                        {{ column.label }}
                    </el-checkbox>
                    <!--五个一行-->
                    <div style="margin-top: 20px" v-if="(index + 1) % 5 === 0" :key="column.property" />
                </template>
            </el-checkbox-group>
        </ef-dialog>
    </div>
</template>

<script>
import EfDialog from 'components/EfDialog';

export default {
    name: 'CheckTableShowColumnDialog',
    components: { EfDialog },
    data() {
        return {
            //待处理的table列
            tableColumns: [],
            checkedColumnPropertyArr: [],
            dialogFormVisible: false,
            checkAllFlag: false,
        };
    },
    computed: {
        isIndeterminate() {
            const checkedCount = this.checkedColumnPropertyArr.length;
            const allCount = this.tableColumns.length;
            return checkedCount > 0 && checkedCount < allCount;
        },
    },
    methods: {
        show(tableColumns = []) {
            tableColumns.forEach((e) => {
                this.tableColumns.push(new TableColumn(e.property, e.label, e.show));
                if (e.show) {
                    this.checkedColumnPropertyArr.push(e.property);
                }
            });
            this.dialogFormVisible = true;
            this.checkAllFlag = this.enableCheckAll();
        },
        confirmSelection() {
            const showColumns = this.tableColumns.map((e) => {
                const show = this.checkedColumnPropertyArr.findIndex((e1) => e1 === e.property) >= 0;
                return { property: e.property, label: e.label, show: show };
            });
            this.$emit('checkTableShowColumn', showColumns);
            //关闭窗口
            this.dialogFormVisible = false;
        },
        handleCheckedChange() {
            this.checkAllFlag = this.enableCheckAll();
        },
        checkAllChange(checkAllFlag) {
            this.checkedColumnPropertyArr = [];
            this.tableColumns = this.tableColumns.map((e) => {
                if (checkAllFlag) {
                    this.checkedColumnPropertyArr.push(e.property);
                }
                return { property: e.property, label: e.label, show: checkAllFlag };
            });
        },
        enableCheckAll() {
            const checkedCount = this.checkedColumnPropertyArr.length;
            const allCount = this.tableColumns.length;
            return checkedCount === allCount;
        },
    },
};

class TableColumn {
    constructor(property, label, show) {
        this.property = property;
        this.label = label;
        this.show = show;
    }
}
</script>
