<template>
    <el-dialog v-bind="$attrs" v-on="$listeners" :visible.sync="show" :destroyOnClose="true" @close="close">
        <slot />
        <div slot="footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: 'EfDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
        };
    },
    watch: {
        visible: {
            handler(n, o) {
                this.show = n;
            },
            immediate: true,
        },
        show: {
            handler(n, o) {
                this.$emit('update:visible', n);
            },
            immediate: true,
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        close() {
            Object.assign(this.$parent.$data, this.$parent.$options.data());
        },
    },
};
</script>
